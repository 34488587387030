<template>
  <Layout :pagetitle="'Pedidos'">
    <div class="row">
      <div class="col-lg-12">

        <div
          class="modal fade bs-example-modal-center"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  Números do pedido #{{ current?.id }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div v-if="current?.status != 'CANCELED' && current?.plain_numbers">
                <span
                  v-for="n in current?.plain_numbers ?? []"
                  :key="n"
                  class="badge"
                  style="font-size: 15px;"
                >{{ n }}</span>
              </div>

              <div class="modal-body" v-if="current?.status == 'CANCELED' && !current?.plain_numbers">
                <div class="alert alert-danger" role="alert">
                    <i class="mdi mdi-block-helper me-1"></i>
                    Este pedido faz parte de uma nova versão dos sorteios, não serão mais salvos os números que foram cancelados!
                  </div>
              </div>

              <div class="modal-body" v-if="current?.status === 'CANCELED'">
                <span
                  v-for="n in current?.plain_numbers ?? []"
                  :key="n"
                  class="badge"
                  style="font-size: 15px;"
                >{{ n }}</span>
              </div>
              
              <div class="modal-foooter m-3" v-if="current?.status === 'CANCELED'">
                  <div class="alert alert-danger" role="alert">
                    <i class="mdi mdi-block-helper me-1"></i>
                    Este pedido está CANCELADO, os números exibidos são apenas informacionais e podem não estar mais disponíveis.
                  </div>
              </div>

              <div class="modal-body" v-if="current?.status !== 'CANCELED' && !current?.plain_numbers">
                <span
                  v-for="n in current?.numbers ?? []"
                  :key="n.id"
                  class="badge"
                  style="font-size: 15px;"
                >{{ n.number }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade bs-examples-modal-center"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  Dados de Pagamento
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body">
                <JsonViewer v-if="current?.gateway_payload" :value="current?.gateway_payload" />
                <div v-else-if="current?.gateway_payload?.confirmation_type">
                  Pedido <strong>Confirmado Manualmente</strong> ás {{ current?.confirmed_at ? $d(current.confirmed_at, 'info') : '(Sem Data de Confirmação)' }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="row">
            <div class="col">
              <h5 class="card-header bg-transparent border-bottom text-uppercase">
                <input
                  v-model="search"
                  class="form-control"
                  type="text"
                  placeholder="Pesquisar por whatsapp ou nome"
                  @input="onSearch"
                >
              </h5>
            </div>
            <div class="col">
              <h5 class="card-header bg-transparent border-bottom">
                <form class="row align-items-center form-control">
                  <div class="hstack gap-3">
                    <div
                      v-for="status in statusTypes"
                      :key="status.value"
                      class="form-check form-switch"
                      style="display:inline"
                    >
                      <label
                        :for="status.value"
                        class="form-check-label"
                      >
                        {{ status.label }}
                      </label>
                      <input
                        v-model="filterBy"
                        class="form-check-input"
                        :value="status.value"
                        :id="status.value"
                        type="checkbox"
                        checked
                      />
                    </div>
                  </div>
                </form>
              </h5>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <router-link
                    style="float: right; margin: 1rem 1rem;"
                    class="btn btn-outline-info waves-effect waves-light"
                    :to="{
                      name: 'raffles-management',
                      params: {
                        id: $route.params.id
                      }}"
                  >
                    <i class="mdi mdi-order-bool-ascending-variant"></i>
                    Gerenciar este sorteio
                  </router-link>
                  <button
                    style="float: right; margin: 1rem 1rem;"
                    class="btn btn-info waves-effect waves-light"
                    @click="exportList"
                    :disabled="exportDisabled"
                  >
                    <i class="mdi mdi-archive-arrow-down-outline"></i>
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Nº do pedido</th>
                    <th>R$ Total</th>
                    <th>Números</th>
                    <th>Cliente</th>
                    <th>WhatsApp</th>
                    <th>Status</th>
                    <th>Data/hora do pedido</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="rowColor(item)"
                  >
                    <th scope="row">#{{ item.id }}</th>
                    <td>{{ $n(item.total, 'currency') }}</td>
                    <td>{{ item.numbers_quantity }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                      <a class="bgn btn-link" target="_blank" :href="whatsappLink(item.name, item.whatsapp)">
                        <i class="mdi mdi-whatsapp"></i>
                        {{ maskPhone(item.whatsapp) }}
                      </a>
                    </td>
                    <td class="text-uppercase" :class="`text-${statusColor(item)}`">
                      <i :class="statusIcon(item)"></i>
                      {{ rowLabel(item) }}
                    </td>
                    <td>{{ $d(item.created_at, 'info') }}</td>
                    <td>
                      <div class="btn-group" role="group" style="width: 100%;">
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          data-bs-toggle="modal"
                          data-bs-target=".bs-example-modal-center"
                          @click="current = item"
                        >
                          <i class="mdi mdi-format-list-numbered"></i>
                          Números
                        </button>
                        <button
                          v-if="item.gateway_payload"
                          type="button"
                          class="btn btn-outline-info"
                          data-bs-toggle="modal"
                          data-bs-target=".bs-examples-modal-center"
                          @click="current = item"
                        >
                          <i class="mdi mdi-credit-card-outline"></i>
                          Dados Pagamento
                        </button>
                        <button
                          v-if="item.status === 'PENDING'"
                          type="button"
                          class="btn btn-outline-success"
                          @click="doApprove(item)"
                        >
                          <i class="mdi mdi-check-decagram"></i>
                          Aprovar
                        </button>
                        <button
                          v-if="item.status === 'PENDING'"
                          type="button"
                          class="btn btn-outline-danger"
                          @click="doCancel(item)"
                        >
                          <i class="mdi mdi-cancel"></i>
                          Cancelar
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer bg-transparent border-top text-muted">
            <app-pagination
              class="pagination"
              v-model="page"
              :pages="pages"
              :range-size="1"
              @update:modelValue="fetchOrders"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import debounce from '@/debounce'
import AppPagination from "@/components/app-pagination";
import { getContestOrders, exportOrdersList, approveOrder, cancelOrder } from './api'
import Layout from '@/views/layouts/main'
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import JsonViewer from 'vue-json-viewer'

const statuses = {
  PROCESSING: {
    color: 'info',
    label: 'Processando',
    icon: 'mdi mdi-autorenew'
  },
  PENDING: {
    color: 'warning',
    label: 'Pendente',
    icon: 'mdi mdi-av-timer'
  },
  CANCELED: {
    color: 'danger',
    label: 'Cancelado',
    icon: 'mdi mdi-cancel'
  },
  CONFIRMED: {
    color: 'success',
    label: 'Confirmado',
    icon: 'mdi mdi-check-circle-outline'
  },
}

const statusTypes = [
  {
    label: 'Cancelado',
    value: 'CANCELED'
  },
  {
    label: 'Confirmado',
    value: 'CONFIRMED'
  },
  {
    label: 'Pendente',
    value: 'PENDING'
  },
  {
    label: 'Processando',
    value: 'PROCESSING'
  }
]

export default {
  components: {
    Layout,
    AppPagination,
    JsonViewer
  },
  setup() {
    const $route = useRoute()
    const items = ref([])
    const search = ref('')
    const filterBy = ref(["CANCELED", "CONFIRMED", "PENDING", "PROCESSING"])
    const current = ref(null)
    const page = ref(1)
    const pages = ref(0)
    const exportDisabled = ref(false)

    const statusColor = (item) => {
      return statuses[item.status]?.color || 'light'
    }

    const statusIcon = (item) => {
      return statuses[item.status]?.icon || 'mdi mdi-av-timer'
    }

    const rowColor = (item) => {
      return `table-${statusColor(item)}`
    }

    const rowLabel = (item) => {
      return statuses[item.status]?.label || 'N/I'
    }

    const exportList = () => {
      exportOrdersList($route.params.id, {
        search: search.value,
        page: page.value,
        filterBy: filterBy.value
      })
    }

    const fetchOrders = () => {
      getContestOrders($route.params.id, {
        search: search.value,
        page: page.value,
        filterBy: filterBy.value
      }).then((data) => {
        pages.value = data.last_page
        items.value = data.data
        exportDisabled.value = !(data.data.length)
      })
    }

    const onSearch = debounce(() => {
      fetchOrders()
    }, 700)

    const doApprove = (item) => {
      Swal.fire({
        title: `Aprovar o pedido #${item.id}?`,
        html: `<div style="line-height: 1.5rem;">
          <p> Após clicar em "Aprovar pagamento",
          o pedido será aprovado e o cliente será notificado por WhatsApp.
          Portanto, é necessário verificar o comprovante enviado pelo cliente
          e se certificar de que o pagamento foi debitado em sua conta. </p>
          <p><strong> Esta ação NÃO pode ser revertida! </strong></p>
          </div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#34c38f',
        confirmButtonText: 'Aprovar pagamento',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.value) {
          approveOrder($route.params.id, item.id).then((data) => {
            Swal.fire('Aprovado!', data.message, 'success')
          }).catch((e) => {
            Swal.fire(
              'Falha ao aprovar!',
              e.response.data.message,
              'error'
            )
          }).finally(() => {
            fetchOrders()
          })
        }
      })
    }

    const doCancel = (item) => {
      Swal.fire({
        title: `Cancelar o pedido #${item.id}?`,
        html: `<div style="line-height: 1.5rem;">
          <p> Após clicar em "Cancelar pedido",
          o pedido será cancelado e os números reservados voltarão a ficar disponíveis para venda.
          Portanto, é necessário entrar em contato com o cliente e confirmar
          se o mesmo não tem interesse em efetivar o pagamento.
          </p>
          <p><strong> Esta ação NÃO pode ser revertida! </strong></p>
          </div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#34c38f',
        confirmButtonText: 'Cancelar pedido',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.value) {
          cancelOrder($route.params.id, item.id).then((data) => {
            fetchOrders()
            Swal.fire('Cancelado!', data.message, 'success')
          }).catch((e) => {
            Swal.fire(
              'Falha ao cancelar!',
              e.response.data.message,
              'error'
            )
          }).finally(() => {
            fetchOrders()
          })
        }
      })
    }

    watch(filterBy, async () => {
      fetchOrders()
    })

    onMounted(() => {
      fetchOrders()
    })

    return {
      page,
      pages,
      items,
      filterBy,
      statusTypes,
      current,
      search,
      onSearch,
      exportList,
      fetchOrders,
      rowColor,
      rowLabel,
      statusColor,
      statusIcon,
      doApprove,
      doCancel,
      exportDisabled,
    }
  }
}
</script>