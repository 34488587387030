import { axios } from '@/axios'
import fileDownload from 'js-file-download'

export const getContestOrders = (id, params = {}) => {
  return axios.get(`contests/manage/${id}/orders`, { params }).then(({ data }) => data)
}

export const exportOrdersList = async (id, params) => {
  const fileName = `lista_pedidos.csv`

  const response = await axios.get(`contests/manage/${id}/orders/export-csv`, { params }, { responseType: 'blob' })

  const fileNameHeader = "x-suggested-filename"
  const suggestedFileName = response.headers[fileNameHeader]
  const effectiveFileName = suggestedFileName || fileName
  fileDownload(response.data, effectiveFileName)
}

export const approveOrder = (contestId, orderId) => {
  return axios.put(`numbers/manage/${contestId}/orders/${orderId}/paid`).then(({ data }) => data)
}

export const cancelOrder = (contestId, orderId) => {
  return axios.delete(`numbers/manage/${contestId}/orders/${orderId}/cancel`).then(({ data }) => data)
}
